<template>
  <div class="zcxy">
    <div class="clearfix table-tools">
      <div class="buttons">
        <a-form layout='inline'>
          <a-form-item v-if="canExcelexport">
            <a-button icon="export" @click="toExport" :loading="exportLoading">导出</a-button>
          </a-form-item>
        </a-form>
      </div>
      <div class="search">
        <a-form layout='inline' @submit="searchList">
          <a-form-item>
            <a-tooltip placement="topLeft" >
              <template slot="title">
                <span>交易订单号</span>
              </template>
              <a-input allowClear v-model.trim="searchData.out_trade_no" placeholder="交易订单号" style="width: 160px"/>
            </a-tooltip>
          </a-form-item>
          <a-form-item>
            <a-tooltip placement="topLeft" >
              <template slot="title">
                <span>手机号</span>
              </template>
              <a-input allowClear v-model.trim="searchData.cellphone" placeholder="学员名称或手机号" style="width: 160px"/>
            </a-tooltip>
          </a-form-item>
          <a-form-item>
            <a-tooltip placement="topLeft" >
              <template slot="title">
                <span>所属校区</span>
              </template>
              <a-select allowClear v-model="searchData.studio_id" mode="multiple" :maxTagCount="1" :maxTagTextLength='5' showSearch :filterOption="filterOption" placeholder="请选择校区" style="width: 210px">
                <a-select-option v-for="(d, index) of studios" :key="index" :value="d['studio_id']">{{ d['filter_name'] }}</a-select-option>
              </a-select>
            </a-tooltip>
          </a-form-item>
          <a-form-item v-if="canIndex">
            <a-button type="primary" html-type="submit" @click="searchList">搜索</a-button>
          </a-form-item>
        </a-form>
      </div>
    </div>
    <div class="table">
      <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
      <a-table size="middle" :pagination="false" :bordered='false' rowKey="out_trade_no"
        :columns="columns" :dataSource="list">
          <template slot="index" slot-scope="text, record , index">
            <span>{{(index+1) + pageParams.perPage * (pageParams.currentPage - 1)}}</span>
          </template>
      </a-table>
    </div>
    <div class="page">
      <a-pagination
        :pageSizeOptions="pageSizeOptions"
        :total="pageParams.totalCount"
        showSizeChanger
        :pageSize="pageParams.perPage"
        v-model="current"
        @change="changePage"
        @showSizeChange="onShowSizeChange"
          :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()}条 `"
      >
          <template slot='buildOptionText' slot-scope='props'>
            <span v-if="props.value!=='100'">{{props.value}}条/页</span>
            <span v-if="props.value==='100'">100条/页</span>
          </template>
        </a-pagination>
    </div>
  </div>
</template>

<script>
const columns = [
  { title: '#', width:'55px', scopedSlots: { customRender: 'index' }, key: 'index'},
  { title: '订单号', width: '200px',  dataIndex: 'out_trade_no', key: '7'},
  { title: '学员名称', dataIndex: 'student_name', key: 'student_name' ,with:'230px',ellipsis:true},
  { title: '手机号码', dataIndex: 'student_cellphone', key: 'student_cellphone' ,with:'160px'},
  { title: '收款理由', dataIndex: 'title', key: '2' ,align:'center',with:'160px',ellipsis:true},
  { title: '交易校区', dataIndex: 'studio_id', key: '4' ,ellipsis:true},
  { title: '支付金额', dataIndex: 'total_fee', key: '6',align:"center"},
  { title: '收款时间', dataIndex: 'dateline', key: '8' ,align:'right'},
]
										
import moment from 'moment'
import C_ITEMS from '@/utils/items'
import authority from '@/common/mixins/authority'
import tableMixins from '@/common/mixins/table'
export default {
  name: 'gjjl',

  data() {
    return {
      loading: false,
      exportLoading:false,
      pageSizeOptions: ['10', '20', '30', '40', '100'],
      current: 1,
      pageParams: {
        currentPage: 1,
        pageCount: 50,
        perPage: 10,
        totalCount: 0
      },
      columns,
      searchParams: {
        "page": 1,
        "per-page": 10,
        "search": {},
        "sort": ''
      },
      list: [],
      current: 1,

      dealStatus: C_ITEMS.dealStatus,
      studios: [],
      consultants: [],
      channels: [],

      searchData: {},
      authType:['market','qrcodepayment'],
      visible: false
    }
  },
  mixins: [ authority, tableMixins ],
  created() {
    this.getStudio()
    this.getConsultant()
    this.getSourceChannel()
  },

  methods: {
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    closeSearch() {
      this.visible = false
    },
    async toExport(){
        this.exportLoading = true
        let exportData = {
            search:this.searchData,
        }
        let res = await this.$store.dispatch('marketingQrcodepaymentExportPaymentlistAction', {data:exportData})
        const blob = new Blob([res], {type: 'application/vnd.ms-excel'})
        if ('download' in document.createElement('a')) { 
            const link = document.createElement('a')
            link.download = `收款记录.${moment().format('YYYY.MM.DD')}.xlsx`
            link.style.display = 'none'
            link.href = URL.createObjectURL(blob)
            document.body.appendChild(link)
            link.click()
            URL.revokeObjectURL(link.href) 
            document.body.removeChild(link)
        } else { //其他浏览器
            navigator.msSaveBlob(blob, fileName)
        }
        this.exportLoading = false
    },
    async getStudio() {
      let res = await this.$store.dispatch('searchBelongStudioAction', {})
      this.studios = res.data
    },

    async getConsultant() {
      let res = await this.$store.dispatch('searchConsultantAction', {})
      this.consultants = res.data
    },

    async getSourceChannel(parent_id) {
      let searchParams = {}
      if (parent_id) {
        searchParams.parent_id = parent_id
      }
      let res = await this.$store.dispatch('searchSourceChannelAction', { data: searchParams })
      if (parent_id) {
        this.channels = res.data
      } else {
        this.parentChannels = res.data
      }
    },
    async getList() {
      if(!this.canIndex){
          this.$message.warning('您暂无权限!!')
          return false
      }
      this.loading = true
      let obj = {}
      let { searchData } = this
      if (searchData) {
        for (let k in searchData) {
          if (k === 'created_date') {
            obj['start_date'] = searchData[k][0]
            obj['end_date'] = searchData[k][1]
          } else {
            obj[k] = searchData[k]
          }
        }
      }
      this.searchParams.search = obj
      let res = await this.$store.dispatch('marketingQrcodepaymentPaymentlistAction', { data: this.searchParams })
      this.list = res.items
      this.pageParams = res._meta
      this.loading = false
    },
  }
}
</script>
