<template>
  <div class="zcxy">
    <div class="clearfix table-tools">
      <div class="buttons">
        <a-form layout='inline'>
          <a-form-item v-if="canSave">
            <a-button icon="plus" type="primary" @click="showEditModal()">二维码收款</a-button>
          </a-form-item>
        </a-form>
      </div>
      <div class="search">
        <a-form layout='inline'>
          <a-form-item @submit="searchList">
            <a-tooltip placement="topLeft" >
              <template slot="title">
                <span>收款理由</span>
              </template>
              <a-input allowClear v-model.trim="searchData.payment_title" placeholder="收款理由" style="width: 160px"/>
            </a-tooltip>
          </a-form-item>
          <a-form-item>
            <a-tooltip placement="topLeft" >
              <template slot="title">
                <span>收款设置</span>
              </template>
              <a-select allowClear v-model="searchData.fixed" placeholder="请选择收款设置" style="width: 140px">
                <a-select-option v-for="(d, index) of fixedStatus" :key="index" :value="d['value']">{{ d['label'] }}</a-select-option>
              </a-select>
            </a-tooltip>
          </a-form-item>
          <a-form-item>
            <a-tooltip placement="topLeft" >
              <template slot="title">
                <span>所属校区</span>
              </template>
              <a-select allowClear v-model="searchData.studio_id" mode="multiple" :maxTagCount="1" :maxTagTextLength='5' showSearch :filterOption="filterOption" placeholder="请选择校区" style="width: 210px">
                <a-select-option v-for="(d, index) of studios" :key="index" :value="d['studio_id']">{{ d['filter_name'] }}</a-select-option>
              </a-select>
            </a-tooltip>
          </a-form-item>
          <a-form-item v-if="canIndex">
            <a-button type="primary" html-type="submit" @click="searchList">搜索</a-button>
          </a-form-item>
        </a-form>
      </div>
    </div>
    <div class="table">
      <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
      <a-table size="middle" :pagination="false" :bordered='false' rowKey="qrcode_id"
        :columns="columns" :dataSource="list">
          <template slot="index" slot-scope="text, record , index">
            <span>{{(index+1) + pageParams.perPage * (pageParams.currentPage - 1)}}</span>
          </template>
          <template slot="image" slot-scope="text, record">
            <LImg :src="text" style="width: 44px; height: 44px; margin: 0 5px 5px 0"/>
          </template>
          <template slot="status" slot-scope="text,record">
            <a-switch checkedChildren="开" unCheckedChildren="关" :checked="text === 1" @change="changeStatus(record)"/>
          </template>
          <template slot="action" slot-scope="text,record">
            <a href="javascript:;" @click="showCopyModal(record)">复制链接</a>
            <a-divider type="vertical" />
            <a @click="creatQRcode(record)" href="javascript:;">二维码</a>
          </template>
      </a-table>
    </div>
    <div class="page">
      <a-pagination
        :pageSizeOptions="pageSizeOptions"
        :total="pageParams.totalCount"
        showSizeChanger
        :pageSize="pageParams.perPage"
        v-model="current"
        @change="changePage"
        @showSizeChange="onShowSizeChange"
          :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()}条 `"
      >
          <template slot='buildOptionText' slot-scope='props'>
            <span v-if="props.value!=='100'">{{props.value}}条/页</span>
            <span v-if="props.value==='100'">100条/页</span>
          </template>
        </a-pagination>
    </div>
    <editModal v-if="editVisible" :item="modalData"/>
    <qrcodeModal v-if='isQrcodeModal' :item='modalData' />
    <copyModal v-if="isCopyModal" :item="modalData"/>
  </div>
</template>

<script>
const columns = [
  { title: '#', width:'55px', scopedSlots: { customRender: 'index' }, key: 'index'},
  { title: '收款内容图片', width: '100px', dataIndex: 'qrcode_logo', key: 'name',scopedSlots: { customRender: 'image' }, },
  { title: '收款理由', dataIndex: 'payment_title', key: '1' },
  { title: '收款金额', dataIndex: 'payment_total', key: '2' },
  { title: '收款方式', dataIndex: 'fixed', key: '3' },
  { title: '所属校区', dataIndex: 'studio_id', key: '4' },
  { title: '累积收款', dataIndex: 'receive_total', key: '5' },
  { title: '收款次数', dataIndex: 'receive_count', key: '6' },
  { title: '添加人', dataIndex: 'created_by', key: 'created_by' },
  { title: '添加时间', dataIndex: 'created_at', key: '7' },
  { title: '状态', dataIndex: 'status', key: '11', width: 80, scopedSlots: { customRender: 'status' }},
  { title: '操作', key: 'operation',align:'right', width: 150, scopedSlots: { customRender: 'action' }},
]
								
import C_ITEMS from '@/utils/items'
import editModal from './editModal'
import qrcodeModal from './qrCodeModal'
import authority from '@/common/mixins/authority'
import tableMixins from '@/common/mixins/table'
import copyModal from '@/views/market/weipages/zxbb/copyModal'
export default {
  name: 'gjjl',
  provide() {
    return {
      parent: this
    }
  },

  components: {
    editModal,
    copyModal,
    qrcodeModal
  },

  data() {
    return {
      loading: false,
      pageSizeOptions: ['10', '20', '30', '40', '100'],
      current: 1,
      pageParams: {
        currentPage: 1,
        pageCount: 50,
        perPage: 10,
        totalCount: 0
      },
      columns,
      searchParams: {
        "page": 1,
        "per-page": 10,
        "search": {},
        "sort": ''
      },
      list: [],
      current: 1,

      fixedStatus: C_ITEMS.fixedStatus,
      studios: [],

      searchData: {},
      isImg:1,
      visible: false,
      modalData: {},
      editVisible: false,
      isCopyModal: false,
      isQrcodeModal: false,
      authType:['market','qrcodepayment'],
    }
  },
  mixins: [ authority, tableMixins ],
  created() {
    this.getStudio()
  },

  methods: {
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    closeSearch() {
      this.visible = false
    },
    async getStudio() {
      let res = await this.$store.dispatch('searchBelongStudioAction', {})
      this.studios = res.data
    },

    async getList() {
      if(!this.canIndex){
          this.$message.warning('您暂无权限!!')
          return false
      }
      this.loading = true
      let obj = {}
      let { searchData } = this
      if (searchData) {
        for (let k in searchData) {
          if (k === 'created_date') {
            obj['start_date'] = searchData[k][0]
            obj['end_date'] = searchData[k][1]
          } else {
            obj[k] = searchData[k]
          }
        }
      }
      this.searchParams.search = obj
      let res = await this.$store.dispatch('marketingQrcodepaymentAction', { data: this.searchParams })
      this.list = res.items
      this.pageParams = res._meta
      this.loading = false
    },
    showEditModal(item) {
      this.modalData = item
      this.editVisible = true
    },
    hideEditModal(type) {
      if (type === 1) {
        this.getList()
      }
      this.editVisible = false
    },
    showCopyModal(item){
        this.modalData = item
        this.isCopyModal = true
    },
    hideCopyModal(){
        this.isCopyModal = false
    },
    changeStatus(record) {
      if (record.status === 1) {
        this.deleteItem(record)
      } else {
        this.recoveryItem(record)
      }
    },
    deleteItem(item) { 
      let that = this
      return new Promise(async (resolve, reject) => {
            let res = await that.$store.dispatch('marketingQrcodepaymentDeleteAction', { data: { qrcode_id: item.qrcode_id } })
            if (res) {
              item.status = 0
              resolve(res)
              this.$message.success('操作成功!')
            }
          }).catch(error => console.log(error))
    },
    recoveryItem(item) {
      let that = this
      return new Promise(async (resolve, reject) => {
            let res = await that.$store.dispatch('marketingQrcodepaymentRecoveryAction', { data: { qrcode_id: item.qrcode_id  } })
            if (res) {
              item.status = 1
              resolve(res)
              this.$message.success('操作成功!')
            }
          }).catch(error => console.log(error));
    },
    creatQRcode(val){
        this.modalData = val
        this.isQrcodeModal = true
    },
    hideQrcodeModal(){
        this.isQrcodeModal = false
    }
  }
}
</script>
